<template>
    <div>    
        <b-sidebar
        id="sidebar-addTransaction"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        >
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h3 class="mb-0">
                    Add New Transaction :
                    </h3>
                    <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                    />
                </div>

                <b-form @submit="onSubmit" @reset="onReset"  class="m-2" 
                id="FORMID">


                    <b-form-group id="name" label="Name:" label-for="input-name" >
                        <b-form-input
                        id="input-name"
                        v-model="form.name"
                        placeholder="Enter Name"
                        ></b-form-input>
                    </b-form-group>

                    <!-- <b-form-group id="amount" label="Amount:" label-for="input-amount">
                        <b-form-input
                        id="input-amount"
                        v-model="form.amount"
                        placeholder="Enter Amount"
                        required
                        ></b-form-input>
                    </b-form-group> -->

     
                    <b-form-group id="amount" label="Amount:" label-for="input-amount">
                        <cleave
                        id="input-amount"
                        v-model="form.amount"
                        class="form-control"
                        placeholder="Enter Amount"
                        :options="amount_field_options"
                        />
                    </b-form-group>

                    <b-form-group
                        id="type"
                        label="Type:"
                        label-for="input-type"
                    >
                        <b-form-select v-model="form.type" :options="type_list"></b-form-select>
                    </b-form-group>

                    <b-form-group
                        v-if="isVendorSelected"
                        id="supplier"
                        label="Supplier"
                        label-for="input-supplier"
                    >
                        <b-form-select v-model="form.supplier" :options="suppliers"></b-form-select>
                    </b-form-group>

                </b-form>
            </template>
            
            <template #footer>
                <div class="flex mb-2 ml-2">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        form="FORMID"
                        variant="success"
                        class="mr-2"
                        type="submit"
                        @click="onSubmit"
                    >
                        Submit
                    </b-button>

                    <b-button
                    type="reset" 
                    variant="danger" 
                    class="mr-2"
                    form="FORMID"
                    >
                        Reset
                    </b-button>

                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-danger"
                        form="FORMID"
                        @click="closeSidebar"
                    >
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge,BFormCheckbox,BAvatar,
  BFormSelect,BFormSelectOption,BFormValidFeedback, BFormInvalidFeedback,BDropdownItem, BDropdown,
  BInputGroupPrepend
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'cleave.js/dist/addons/cleave-phone.us'
import Cleave from 'vue-cleave-component'

export default {
components: {
    BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge, BSidebar,
    BFormCheckbox , BAvatar , BFormSelect , BFormSelectOption, BFormValidFeedback, BFormInvalidFeedback,
    BDropdownItem, BDropdown ,BInputGroupPrepend,Cleave
  },
  directives: {
    Ripple,
  },
  data() {
      return{
        amount_field_options: {
          prefix: '$ ',
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        },
        form: {
            name: '',
            amount: '',
            type: '',
            supplier:''
        },
        type_list:[
          { value: null, text: 'Please select an option' },
          { value: 'Expense', text: 'Expense'},
          { value: 'Weekly Payment', text: 'Weekly Payment' },
          { value: 'Monthly Payment', text: 'Monthly Payment' },
          { value: 'Vendor Payment', text: 'Vendor Payment' }
        ],
    }
  },
  props:{
      suppliers: {
        type: Array,
        required: true,
      }     
  },
  methods:{

      onReset(event) {
        event.preventDefault()

        this.clearForm()
      },
      clearForm(){
        this.form.name = ''
        this.form.amount=''
        this.form.type=''
        this.form.supplier=''
      },
      closeSidebar(){
          this.$root.$emit('bv::toggle::collapse', 'sidebar-addTransaction')
          this.clearForm()
      },
      onSubmit(event){
        event.preventDefault()

        if(this.checkValid){
            axios({
                method: 'post',
                url: process.env.VUE_APP_API+'/accounts',
                data:this.form              
                })
                .then(res=>{
                    if(res.data.success==true){
                        this.$emit('refresh')
                        //this.$root.$emit('bv::toggle::collapse', 'sidebar-addTransaction')
                        this.closeSidebar()
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: 'Success',
                            icon: 'AlertOctagonIcon',
                            text: 'transaction added succesfully',
                            variant:'success',
                            },     
                        },
                        {
                            position:'top-right'
                        })

                        this.clearForm()
                    }
                    else if(res.data.success==false){

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: 'Failure',
                            icon: 'AlertOctagonIcon',
                            text: 'transaction not added',
                            variant:'danger',
                            },     
                        },
                        {
                            position:'top-right'
                        })     
                    }
                })
        }
        else{
            this.$toast({
                component: ToastificationContent,
                props: {
                title: 'Empty Field',
                icon: 'AlertOctagonIcon',
                text: 'enter empty field(s)',
                variant:'danger',
                },     
            },
            {
                position:'top-left'
            })
        }
      },
  },
  computed:{
      checkValid:function(){
          if(!this.isVendorSelected){
            return this.form.name.length != 0 && this.form.amount.length >2 && this.form.type.length != 0
          }
          else{
              return this.form.supplier.length != 0
          }
          },
        isVendorSelected:function(){
            return this.form.type=='Vendor Payment'
        }
    }

}
</script>
